let generateSparkQuery = (constraint) => {
  console.log(constraint)
  if (constraint.operator.name === 'Is Not NULL') {
    return `${constraint.columnName} IS NOT NULL`
  } else if (constraint.operator.name === 'Is NULL') {
    return `${constraint.columnName} IS NULL`
  } else {
    switch (constraint.columnType) {
      case 'string':
        return createStringQuery(constraint)
      case 'long':
        return createLongQuery(constraint)
      case 'double':
        return createDoubleQuery(constraint)
      case 'timestamp':
        return createTimestampQuery(constraint)
      case 'boolean':
        return createBooleanQuery(constraint)
      default:
        break;
    }
  }
}

let createStringQuery = (constraint) => {
  switch (constraint.operator.name) {
    case 'Is':
      return `${constraint.columnName} = '${constraint.value}'`
    case 'Is Not':
      return `${constraint.columnName} <> '${constraint.value}'`
    case 'Is In Set':
      return `array_contains(${generateStringArray(constraint.value)}, ${constraint.columnName})`
    case 'Is Not In Set':
      return `NOT array_contains(${generateStringArray(constraint.value)}, ${constraint.columnName})`
    case 'Is Like':
      return `${constraint.columnName} LIKE '${constraint.value}'`
    default:
      break;
  }
}

let createDoubleQuery = (constraint) => {
  switch (constraint.operator.name) {
    case 'Is':
      return `${constraint.columnName} = ${parseInt(constraint.value)}`
    case 'Is Not':
      return `${constraint.columnName} <> ${parseInt(constraint.value)}`
    case 'Less Than':
      return `${constraint.columnName} < ${parseInt(constraint.value)}`
    case 'Less Than or Equal to':
      return `${constraint.columnName} <= ${parseInt(constraint.value)}`
    case 'Greater Than':
      return `${constraint.columnName} > ${parseInt(constraint.value)}`
    case 'Greater Than or Equal to':
      return `${constraint.columnName} >= ${parseInt(constraint.value)}`
    default:
      break;
  }
}

let createLongQuery = (constraint) => {
  switch (constraint.operator.name) {
    case 'Is':
      return `${constraint.columnName} = ${parseFloat(constraint.value)}`
    case 'Is Not':
      return `${constraint.columnName} <> ${parseFloat(constraint.value)}`
    case 'Less Than':
      return `${constraint.columnName} < ${parseFloat(constraint.value)}`
    case 'Less Than or Equal to':
      return `${constraint.columnName} <= ${parseFloat(constraint.value)}`
    case 'Greater Than':
      return `${constraint.columnName} > ${parseFloat(constraint.value)}`
    case 'Greater Than or Equal to':
      return `${constraint.columnName} >= ${parseFloat(constraint.value)}`
    default:
      break;
  }
}

let createTimestampQuery = (constraint) => {
  switch (constraint.operator.name) {
    case 'Is':
      return `${constraint.columnName} = to_date('${constraint.value}')`
    case 'Is Not':
      return `${constraint.columnName} <> to_date('${constraint.value}')`
    case 'Before':
      return `${constraint.columnName} < to_date('${constraint.value}')`
    case 'After':
      return `${constraint.columnName} > to_date('${constraint.value}')`
    case 'On or Before':
      return `${constraint.columnName} <= to_date('${constraint.value}')`
    case 'On or After':
      return `${constraint.columnName} >= to_date('${constraint.value}')`
    default:
      break;
  }
}

let createBooleanQuery = (constraint) => {
  switch (constraint.operator.name) {
    case 'Is':
      return `${constraint.columnName} = ${constraint.value}`
    case 'Is Not':
      return `${constraint.columnName} <> ${constraint.value}`
    default:
      break;
  }
}

let generateStringArray = function(vals) {
  let arrayVal = `array(`
    vals.map((val, index) => {
      arrayVal += `'${val}'`
      if (index < vals.length - 1) {
        arrayVal += ', '
      }
    })
    arrayVal += ')'
  console.log(arrayVal)
  return arrayVal
} 

export { 
  generateSparkQuery
}