export default [
	{
		type: "universal",
		name: "Is Not NULL",
		cardinality: "none"
	},
	{
		type: "universal",
		name: "Is NULL",
		cardinality: "none"
	}
]