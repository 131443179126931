export default [
	{
		type: "double",
		name: "Is",
		cardinality: "single",
		supportsEnum: true
	},
	{
		type: "double",
		name: "Is Not",
		cardinality: "single",
		supportsEnum: true
	},
	{
		type: "double",
		name: "Less Than",
		cardinality: "single"
	},
	{
		type: "double",
		name: "Less Than or Equal to",
		cardinality: "single"
	},
	{
		type: "double",
		name: "Greater Than",
		cardinality: "single"
	},
	{
		type: "double",
		name: "Greater Than or Equal to",
		cardinality: "single"
	}
]