let createNewBuyersFilter = (accessRule, companies) => {
	return {
		name: "buyers",
		type: "stringLimited",
		title: "Buyers",
		description: "Decide who this access rule applies to.",
		value: accessRule.company_constraint ? "custom" : "default",
		options: [
			{
				label: `Sell to all buyers`,
				value: 'default',
			},
			{
				label: 'Custom',
				value: 'custom',
			}
		],
		customOption: {
			config: {
				searchable: true,
				multiple: true,
				items: companies,
				text: {
					include: 'Select the companies you want to sell to.',
					exclude: 'Select the companies you don’t want to sell to.'
				}
			},
			value: {
				listType: accessRule.company_constraint && accessRule.company_constraint.type === "exclusion" ? "exclude" : "include",
				items: accessRule.company_constraint ? accessRule.company_constraint.company_ids : []
			}  
		}
	}
}

export {
	createNewBuyersFilter
}