export default [
	{
		type: "string",
		name: "Is",
		cardinality: "single",
		supportsEnum: true
	},
	{
		type: "string",
		name: "Is Not",
		cardinality: "single",
		supportsEnum: true
	},
	{
		type: "string",
		name: "Is In Set",
		cardinality: "multiple"
	},
	{
		type: "string",
		name: "Is Not In Set",
		cardinality: "multiple"
	},
	{
		type: "string",
		name: "Is Like",
		cardinality: "single"
	}
]