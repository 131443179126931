export default [
	{
		type: "timestamp",
		name: "Is",
		cardinality: "single"
	},
	{
		type: "timestamp",
		name: "Is Not",
		cardinality: "single"
	},
	{
		type: "timestamp",
		name: "Before",
		cardinality: "single"
	},
	{
		type: "timestamp",
		name: "After",
		cardinality: "single"
	},
	{
		type: "timestamp",
		name: "On or Before",
		cardinality: "single"
	},
	{
		type: "timestamp",
		name: "On or After",
		cardinality: "single"
	}
]