export default [
	{
		type: "array",
		name: "Contains",
		cardinality: "single"
	},
	{
		type: "array",
		name: "Contains Any",
		cardinality: "multiple"
	}
]