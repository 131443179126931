import UniversalOperators from './operators/universal'
import ArrayOperators from './operators/array'
import DoubleOperators from './operators/double'
import LongOperators from './operators/long'
import ObjectOperators from './operators/object'
import StringOperators from './operators/string'
import TimestampOperators from './operators/timestamp'
import BooleanOperators from './operators/boolean'

const Operators = [
	...UniversalOperators,
	...ArrayOperators,
	...DoubleOperators,
	...LongOperators,
	...ObjectOperators,
	...StringOperators,
	...TimestampOperators,
	...BooleanOperators
]

function getColumnType(column) {
	return column.type === 'timestamptz' ? 'timestamp' : column.type
}

function columnHasEnum(column) {
	return Object.keys(column).indexOf('enum') > -1
}

const AvailableOperatorTypes =  {
	array: [
		...UniversalOperators,
		// ...ArrayOperators
	],
	string: [
		...UniversalOperators,
		...StringOperators
	],
	double: [
		...UniversalOperators,
		...DoubleOperators
	],
	long: [
		...UniversalOperators,
		...LongOperators
	],
	timestamp: [
		...UniversalOperators,
		...TimestampOperators
	],
	boolean: [
		...UniversalOperators,
		...BooleanOperators
	],
	object: [
		...UniversalOperators,
		// ...ObjectOperators
	]
}

export {
	getColumnType,
	columnHasEnum,
	Operators,
	AvailableOperatorTypes
}