export default [
	{
		type: "double",
		name: "Is",
		cardinality: "single"
	},
	{
		type: "double",
		name: "Is Not",
		cardinality: "single"
	}
]