<template lang="pug">
  .access-rule-constraint(v-if="tmpConstraint && columns")
    template(v-if="tmpConstraint.isSpark")
      NioTextField.spark-query(
        v-model="tmpConstraint.value"
        label="Spark SQL query"
        small
      )
    template(v-else)
      NioSelect.column-name(
        v-model="tmpConstraint.columnName"
        :items="columns"
        item-text="name"
        item-value="name"
        label="Column name"
        small
        @change="computeAvailableOperators($event)"
      )
      .constraint-controls
        NioSelect.operator(
          v-model="tmpConstraint.operator"
          :value.sync="tmpConstraint.operator"
          :items="availableOperators"
          :class="toKebabCase(columnType)"
          item-text="name"
          item-value="name"
          label="Operator"
          small
          fluid-width
          @change="setOperator($event)"
        )
        .value
          template(v-if="tmpConstraint.operator.cardinality === 'single'")
            template(
              v-if="['string', 'double', 'long'].includes(columnType)"
            )
              template(v-if="columnHasEnum && tmpConstraint.operator.supportsEnum")
                NioSelect(
                  v-model="tmpConstraint.value"
                  :items="columnEnumValues"
                  label="Select values"
                  small
                )
                  template(v-slot:selection="{ item, index }")
                    span.v-select__selection(v-if="index === 0") 
                      NioPill(
                        selected-value
                      ) {{ item }}
                    span.v-select__selection(v-if="index === 1")
                      NioPill(
                        selected-value
                      ) {{ item }}
                    span.v-select__selection.more(v-if="index === 2 && tmpConstraint.value.length === 3") (+{{ tmpConstraint.value.length - 2 }} other)
                    span.v-select__selection.more(v-if="index === 2 && tmpConstraint.value.length > 3 ") (+{{ tmpConstraint.value.length - 2 }} others)
              template(v-else)
                NioTextField(	
                  v-model="tmpConstraint.value"
                  :value="tmpConstraint.value"
                  :type="columnType === 'double' || columnType === 'long' ? 'number' : 'text'"
                  :granularity="columnType === 'double' ? 1 : undefined"
                  label="Enter value"
                  small
                )
            template(v-if="columnType === 'boolean'")
              NioSelect(
                v-model="tmpConstraint.value"
                :items="['true', 'false']"
                label="Select value"
                small
              )
            template(v-if="columnType === 'timestamp'")
              NioDateField.column-name(
                v-model="tmpConstraint.value"    
                :value="tmpConstraint.value"        
                label="Select date"
                small
              )
            template(v-if="columnType === 'object'") Object
            template(v-if="columnType === 'array'") Array
          template(v-if="tmpConstraint.operator.cardinality === 'multiple'")   
            NioTagsField.value(
              v-if="tmpConstraint.operator.cardinality === 'multiple'"
              v-model="tmpConstraint.value"
              :value="tmpConstraint.value"
              label="Enter values"
              small
              solo
            )
    button.remove-constraint(
      @click="deleteConstraint"
    )
      NioIcon(
        :color="getThemeColor('primaryDark')" 
        :size="14"
        name="utility-minus" 
      )
</template>

<script>

import { getThemeColor } from '@narrative.io/tackle-box/src/modules/app/theme/theme'
import { toKebabCase } from '@/modules/helpers'
import { Operators, AvailableOperatorTypes, getColumnType, columnHasEnum } from './operators'
import moment from 'moment'
import * as Dataset from '@/modules/dataset'

export default {
  props: {
    constraint: { type: Object, required: true },
    columns: { type: Array, required: true }
  },
  data: () => ({
    tmpConstraint: null,
    availableOperators: [],
    columnType: null,
    columnHasEnum: false,
    columnEnumValues: [],
    selectedColumn: null,
    operators: Operators,
    availableOperatorTypes: AvailableOperatorTypes
  }),	
  watch: {
    tmpConstraint: {
      deep: true,
      handler(val) {
        this.$emit('update', val)
      }
    },
    constraint: {
      deep: true,
      handler(val) {
        this.tmpConstraint = this.constraint
      }
    }
  },
  mounted() {
    this.tmpConstraint = this.constraint
    if (!this.tmpConstraint.isSpark) {
      this.computeAvailableOperators(this.tmpConstraint.columnName)
    }
  },
  methods: {
    getThemeColor(themeColor) {
      return getThemeColor(themeColor)
    },
    toKebabCase(val) {
      return toKebabCase(val)
    },
    setOperator(operatorName) {
      this.tmpConstraint.operator = this.operators.find(operator => operator.name === operatorName)
      if (this.tmpConstraint.columnType === 'timestamp') {
        const date = new Date()
        this.tmpConstraint.value = moment(date).format('YYYY-MM-DD')
      } else {
        this.tmpConstraint.value = null
      }
    },
    computeAvailableOperators(columnName) {
      this.selectedColumn = this.columns.find(column => column.name === columnName)
      this.columnHasEnum = columnHasEnum(this.selectedColumn)
      this.columnEnumValues = this.columnHasEnum ? this.selectedColumn.enum.sort() : []
      this.columnType = getColumnType(this.selectedColumn)
      
      this.availableOperators = this.availableOperatorTypes[this.columnType]
      if (this.tmpConstraint) {
        this.tmpConstraint.columnType = this.columnType
        this.setOperator(this.availableOperators[0].name)
      }
    },
    deleteConstraint() {
      this.$emit('delete', this.constraint.id)
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.access-rule-constraint
  display: flex
  justify-content: space-between
  & > * + *
    margin-left: 8px
  .nio-text-field ::v-deep label
    transform: translateY(-3px)
  .nio-date-field
    height: 2.5rem
    ::v-deep input
      padding: 0rem 1rem
      font-size: 0.875rem
    &.currency ::v-deep input
      padding: 0rem 1rem 0rem 0.75rem
    ::v-deep label
      padding-left: 1rem
      top: 0.625rem
    ::v-deep .v-input__slot
      height: 100%  
      border-width: 0.0625rem
  .column-name
    width: 245px
    flex-grow: 0
    flex-shrink: 0
  .constraint-controls
    display: flex
    flex-grow: 2
    & > * + *
      margin-left: 8px
    .operator
      flex-grow: 0
      flex-shrink: 2
      &.string, &.boolean, &.enum
        width: 180px
      &.number
        width: 230px
    .value
      flex-grow: 2
    .nio-select:not(.operator)
      ::v-deep .v-select__selections
        margin-top: 2px
      ::v-deep .v-select__selection
        margin-bottom: 0px
        &.more
          margin-bottom: 3px
      ::v-deep .v-select__selection + .v-select__selection
        margin-left: 4px
    .nio-tags-field
      min-height: 40px
      ::v-deep label
        display: flex !important
        top: 0.425rem !important
        left: -5px !important
      ::v-deep .v-select__selections
        padding: 0px
        padding-top: 0.25rem !important
        paddiong-top: 0.15rem !important
      ::v-deep label
        display: none
  .remove-constraint
    width: 40px
    height: 40px
    flex-shrink: 0
    flex-grow: 0
    border: 1px solid $c-primary-light
    border-radius: 0.5rem
    &:hover
     cursor: pointer
     background-color: $c-canvas 
</style>