const MockDatasets = [
	{
		name: 'My first dataset',
		id: 12,
		description: 'Something somethin something',
		columns: 8,
		status: 'Active',
		tags: ['tag1', 'tag2'],
		created_at: new Date()
	},
	{
		name: 'My second dataset',
		id: 15,
		description: 'Something somethin something',
		columns: 10,
		tags: ['tag1', 'tag2'],
		status: 'Active',
		created_at: new Date()
	},
	{
		name: 'My third dataset',
		id: 8,
		description: 'Something somethin something',
		columns: 12,
		status: 'Active',
		tags: ['tag1', 'tag2'],
		created_at: new Date()
	},
	{
		name: 'My fourth dataset',
		id: 31,
		description: 'Something somethin something',
		columns: 6,
		status: 'Stopped',
		tags: ['tag1', 'tag2'],
		created_at: new Date()
	}
]

export default MockDatasets